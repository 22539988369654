<script>
  import { onMount } from "svelte";

  export let environment;

  let lines = ["... and", "I'm", "never", "gonna", "give", "you", "up"];

  let visibleLines = [];
  let showGif = false;

  onMount(() => {
    let index = 0;
    const interval = setInterval(() => {
      if (index < lines.length) {
        visibleLines = [...visibleLines, lines[index]];
        index++;
      } else {
        clearInterval(interval);
        showGif = true;
      }
    }, 250);
  });
</script>

<main
  class="min-h-screen flex flex-col items-center justify-center bg-pink-400"
>
  <div class="text-center">
    <h1 class="text-4xl mb-8">I'm {environment}</h1>

    <div class="text-xl space-y-2">
      {#each visibleLines as line}
        <div class="fade-in">{line}</div>
      {/each}
    </div>

    {#if showGif}
      <div class="mt-8">
        <div
          class="tenor-gif-embed"
          data-postid="22113173"
          data-share-method="host"
          data-aspect-ratio="0.84375"
          data-width="100%"
        >
          <a
            href="https://tenor.com/view/rick-roll-rick-ashley-never-gonna-give-you-up-gif-22113173"
            >Rick Roll Rick Ashley GIF</a
          >from
          <a href="https://tenor.com/search/rick+roll-gifs">Rick Roll GIFs</a>
        </div>
        <script
          type="text/javascript"
          async
          src="https://tenor.com/embed.js"
        ></script>
      </div>
    {/if}
  </div>
</main>

<style>
  .fade-in {
    animation: fadeIn 0.5s ease-in;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
</style>
